import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

Vue.use(Vuex);

const defaultState = () => ({
  config: {},
  apiException: '',
  apiMessage: '',
});

export default new Vuex.Store({
  state: defaultState(),
  getters: {
    apiException(state) {
      return state?.apiException || '';
    },

    apiMessage(state) {
      return state?.apiMessage || '';
    },

    apiError(state) {
      return {
        exception: state?.apiException || '',
        message: state?.apiMessage || '',
      };
    },
  },
  mutations: {
    SET_CONFIG(state, config) {
      Object.assign(state, { config });
    },

    SET_API_ERROR(state, { apiException, apiMessage }) {
      Object.assign(state, { apiException, apiMessage });
    },

    RESET_ERROR(state) {
      Object.assign(state, { apiException: '', apiMessage: '' });
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
  },
  actions: {
    RESET_ERROR({ commit }) {
      commit('RESET_ERROR');
    },
    RESET_STATE({ commit }) {
      commit('RESET_STATE');
    },
  },
  modules: {},
  plugins: [
    pathify.plugin,
  ],
});
