<template>
  <InputDropdown
    v-bind="$attrs"
    :disabled="disabled"
    @input="$emit('input', $event)"
  >
    <option
      value=""
      aria-describedby="suffix"
    >
      Select
    </option>
    <option value="ATTY">
      ATTY
    </option>
    <option value="CEO">
      CEO
    </option>
    <option value="CFO">
      CFO
    </option>
    <option value="CIO">
      CIO
    </option>
    <option value="CPA">
      CPA
    </option>
    <option value="DC">
      DC
    </option>
    <option value="DDS">
      DDS
    </option>
    <option value="DMD">
      DMD
    </option>
    <option value="DO">
      DO
    </option>
    <option value="DVM">
      DVM
    </option>
    <option value="ESQ">
      ESQ
    </option>
    <option value="I">
      I
    </option>
    <option value="II">
      II
    </option>
    <option value="III">
      III
    </option>
    <option value="IV">
      IV
    </option>
    <option value="V">
      V
    </option>
    <option value="JR">
      JR
    </option>
    <option value="MD">
      MD
    </option>
    <option value="PHD">
      PHD
    </option>
    <option value="PRES">
      PRES
    </option>
    <option value="RET">
      RET
    </option>
    <option value="SR">
      SR
    </option>
    <option value="VP">
      VP
    </option>
  </InputDropdown>
</template>

<script>
import InputDropdown from './input-dropdown.vue';

export default {
  name: 'InputSuffix',
  components: {
    InputDropdown,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
