<template>
  <div class="c-tooltip">
    <div class="c-tooltip__pass-through">
      <slot />
    </div>
    <Popover
      :placement="placement"
      offset="25"
      @show="$emit('show', $event)"
    >
      <button
        type="button"
        class="c-tooltip__button"
      >
        <span class="sr-only">
          <slot name="accessible" />
        </span>
        <template v-if="btnText">
          {{ btnText }}
        </template>
        <img
          v-else
          :src="require('./icon.svg')"
          alt="tooltip icon"
          class="not-sr-only"
        >
      </button>

      <template slot="popover">
        <slot name="tip" />
      </template>
    </Popover>
  </div>
</template>

<script>
import { VPopover } from 'v-tooltip';

export default {
  name: 'Tooltip',
  components: {
    Popover: VPopover,
  },
  props: {
    btnText: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'right',
    },
  },
  mounted() {
    this.getPopoverID();
  },
  methods: {
    getPopoverID() {
      const popoverDiv = document.querySelectorAll('[aria-describedby^="popover_"]');
      popoverDiv.forEach((div) => {
        const tooltip = div;
        tooltip.parentElement.id = tooltip.getAttribute('aria-describedBy');
      });
    },
  },
};
</script>
