<template>
  <!-- eslint-disable max-len -->
  <transition name="fade">
    <div
      v-if="value"
      ref="modalContainer"
      class="c-modal"
      tabindex="0"
    >
      <div
        class="c-modal__backdrop"
        @click="$emit('input', false); $emit('user-dismissed');"
      />
      <div
        :class="dialogClass"
        class="c-modal__dialog"
      >
        <button
          v-if="closeX"
          :id="closeId"
          type="button"
          class="c-modal__close"
          @click="$emit('input', false); $emit('user-dismissed');"
        >
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 15 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                transform="translate(-928.000000, -286.000000)"
                fill="#333366"
                stroke="#333366"
                stroke-width="1.5"
              >
                <g>
                  <g transform="translate(479.000000, 267.000000)">
                    <g transform="translate(450.000000, 20.000000)">
                      <path d="M6.93473593,6.50015396 L12.2587116,1.17561781 C12.3787874,1.05552938 12.3787874,0.860308709 12.2587116,0.739604442 C12.1386358,0.620131853 11.9434357,0.620131853 11.8233599,0.739604442 L6.49938423,6.06475643 L1.17479277,0.739604442 C1.05533275,0.620131853 0.860132628,0.620131853 0.740056841,0.739604442 C0.619981053,0.860308709 0.619981053,1.05552938 0.740056841,1.17561781 L6.06403253,6.50015396 L0.740056841,11.8246901 C0.619981053,11.9447785 0.619981053,12.1399992 0.740056841,12.2607035 C0.800402621,12.3204398 0.879221599,12.35 0.958040578,12.35 C1.03685956,12.35 1.11567853,12.3198239 1.17602432,12.2607035 L6.5,6.93555149 L11.8239757,12.2607035 C11.8843215,12.3204398 11.9631404,12.35 12.0419594,12.35 C12.1207784,12.35 12.1995974,12.3198239 12.2599432,12.2607035 C12.3800189,12.1399992 12.3800189,11.9447785 12.2599432,11.8246901 L6.93596747,6.50015396 L6.93473593,6.50015396 Z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span class="sr-only">Close</span>
        </button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    closeX: {
      type: Boolean,
      default: true,
    },
    dialogClass: {
      type: String,
      default: '',
    },
    closeId: {
      type: String,
      default: '',
      required: false,
    },
  },
  watch: {
    value(currentValue) {
      if (currentValue) {
        this.disablePageScrolling();
        this.$nextTick()
          .then(() => {
            this.$refs.modalContainer.focus();
          });
      } else {
        this.enablePageScrolling();
      }
    },
  },
  created() {
    if (this.value) {
      this.disablePageScrolling();
    }
  },
  beforeDestroy() {
    this.enablePageScrolling();
  },
  methods: {
    disablePageScrolling() {
      document.body.classList.add('overflow-hidden');
    },
    enablePageScrolling() {
      document.body.classList.remove('overflow-hidden');
    },
  },
};
</script>
