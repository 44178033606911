<template>
  <progress
    id="individual-progress-bar"
    class="single__progress-bar"
    :role="role"
    :max="ariaValuemax"
    :value="ariaValuenow"
    :aria-valuenow="ariaValuenow || ariaValuetext"
    :aria-valuemin="ariaValuemin || ariaValuetext"
    :aria-valuemax="ariaValuemax"
    :aria-valuetext="textValue"
  />
</template>

<script>
export default {
  name: 'IndividualProgressBar',
  props: {
    role: {
      type: String,
      required: false,
      default: 'progressbar',
    },
    ariaValuetext: {
      type: String,
      default: '',
      required: false,
    },
    ariaValuenow: {
      type: Number,
      default: 0,
      required: true,
    },
    ariaValuemin: {
      type: Number,
      default: 0,
      required: true,
    },
    ariaValuemax: {
      type: Number,
      default: 1,
      required: true,
    },
    isIndeterminate: {
      type: Boolean,
      default: false,
      required: false,
    },
    value: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0 && value <= 100,
    },
    simplify: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    textValue() {
      return this.ariaValuemin || this.ariaValuemax ? null : this.ariaValuetext;
    },
  },
};
</script>

<style scoped>
/* ::-webkit-progress-inner-element */
/* ::-moz-progress-bar */
progress[value]::-webkit-progress-bar {
  background-color: #ededed;
}
progress {
  color: #333366;
}
progress::-moz-progress-bar {
  background: #333366;
}
progress::-webkit-progress-value {
  background: #333366;
}
progress::-webkit-progress-bar {
  background: #333366;
}
.single__progress-bar {
  background-color: #ededed;
  margin: 20px 0;
  width: 100%;
  height: 16px;
  transform: skew(-30deg);
}
.simplify_progress {
  margin: 10px 0px;
}
</style>
