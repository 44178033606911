<template>
  <li
    class="individual-progress-bar-step-item"
    :data-is-active="isActive"
  >
    <img
      width="12px"
      height="12px"
      loading="lazy"
      decoding="async"
      :src="imageSrc"
      :alt="alt"
    >
    {{ label }}
  </li>
</template>

<script>

export default {
  name: 'IndividualProgressBarStepItem',
  props: {
    imageSrc: {
      type: String,
      default: '',
      required: true,
    },
    alt: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>
