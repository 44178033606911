<template>
  <div class="c-error-alert">
    <img
      :src="$options.redAlertSVG"
      alt="Red caution symbol"
      class="c-error-alert__icon"
    >
    <div class="c-error-alert__message">
      <slot />
    </div>
  </div>
</template>

<script>
import redAlertSVG from '../../assets/_icon-red-alert.svg';

export default {
  name: 'ErrorAlert',
  redAlertSVG,
};
</script>
