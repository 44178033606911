import COAC from '../class/coac';

export default {
  install(Vue, options = {}) {
    /** @memberof Vue.prototype */
    const coac = new COAC(options);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$coac = coac;
  },
};
