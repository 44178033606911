<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <div class="c-progress-bar">
    <div
      v-for="n in steps"
      class="c-progress-bar__step"
      :class="{
        'c-progress-bar__step--active': n <= current,
        'c-progress-bar__step--short': short,
      }"
    >
      <div
        v-if="current === n"
        class="c-progress-bar__copy lg:visible"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    steps: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      default: 1,
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
