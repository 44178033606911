<template>
  <div class="c-input-text">
    <label
      :for="name"
      class="c-input-text__label c-input-label"
    >
      <template v-if="label">{{ label }}</template>
      <template v-else>
        <slot name="label" />
      </template>
    </label>
    <input
      :id="name"
      :value="value"
      :placeholder="placeholder"
      :name="fieldName"
      :class="{ 'errors c-input-field--error': hasError }"
      :autocomplete="generatedAutocomplete"
      :disabled="disabled"
      :clear="clear"
      :maxlength="length"
      class="c-input-text__field c-input-field"
      type="text"
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
    <span
      v-if="attachClear && !disabled"
      :id="`clear_${name}`"
      class="c-input-text__clear"
      @click="$emit('clear',name)"
    >
      &times;
    </span>
    <p
      :id="`${name}-error`"
      class="c-input-text__helper c-input-helper"
    >
      <slot name="helper" />
    </p>
    <slot />
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  name: 'InputText',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    length: {
      type: String,
      default: '',
    },
  },
  computed: {
    attachClear() {
      return this.clear && this.value.length >= 1;
    },
    fieldName() {
      return this.autocomplete !== 'on' ? uuid() : this.name;
    },
    generatedAutocomplete() {
      if (navigator.userAgent.indexOf('Chrome') < 0) return this.autocomplete;

      return this.autocomplete !== 'on' ? this.fieldName : 'on';
    },
  },
};
</script>
