<template>
  <div
    :class="{ 'c-input-checkbox--error': hasError }"
    class="c-input-checkbox"
  >
    <input
      :id="name"
      :value="name"
      :checked="value"
      :name="name"
      type="checkbox"
      class="c-input-checkbox__field sr-only"
      @change.prevent="$emit('input', !value)"
    >
    <label
      :for="name"
      class="c-input-checkbox__label c-input-label"
    >
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'Inputcheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
