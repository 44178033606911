<template>
  <footer class="app-footer u-no-print">
    <div class="app-footer__content">
      <a
        ref="usps-logo-link"
        href="https://www.usps.com/"
        rel="noopener noreferrer"
        target="_blank"
        class="app-footer__logo-link"
      >
        <img
          ref="usps-logo"
          :src="$options.uspsSVG"
          alt="The profile of an eagle's head adjoining the words USPS.com."
          class="app-footer__logo"
        >
      </a>
      <div class="app-footer__nav-links mt-6">
        <div class="app-footer__nav-group">
          <p class="app-footer__nav-heading">
            Helpful Links
          </p>
          <ul class="mt-1">
            <li>
              <a
                ref="contact-us"
                href="https://www.usps.com/help/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Contact Us</a>
            </li>
            <li>
              <a
                ref="site-index"
                href="https://www.usps.com/globals/site-index.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Site Index</a>
            </li>
            <li>
              <a
                ref="faqs"
                href="http://faq.usps.com/"
                rel="noopener noreferrer"
                target="_blank"
              >FAQs</a>
            </li>
            <li>
              <a
                id="footer-mail-forwarding-instructions"
                ref="mail-fwd-instructions"
                :href="`${mgoUrl}/mgo/mail-forwarding-instructions`"
                target="_blank"
              >
                Mail Forwarding Instructions
              </a>
            </li>
            <li>
              <a
                id="footer-temporary-moves"
                ref="temporary-moves"
                :href="`${mgoUrl}/mgo/temporary-moves`"
                target="_blank"
              >
                Temporary Moves
              </a>
            </li>
            <li>
              <a
                id="footer-when-to-file"
                ref="when-to-file"
                :href="`${mgoUrl}/mgo/when-to-file`"
                target="_blank"
              >
                When to File
              </a>
            </li>
            <li>
              <a
                id="footer-types-of-forwarding"
                ref="types-of-forwarding"
                :href="`${mgoUrl}/mgo/types-of-forwarding`"
                target="_blank"
              >
                Types of Forwarding
              </a>
            </li>
          </ul>
        </div>
        <div class="app-footer__nav-group">
          <p class="app-footer__nav-heading">
            USPS Jobs
          </p>
          <ul class="mt-1">
            <li>
              <a
                ref="careers"
                href="http://about.usps.com/careers/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Careers</a>
            </li>
          </ul>
          <p class="app-footer__nav-heading mt-3">
            On about.usps.com
          </p>
          <ul class="mt-1">
            <li>
              <a
                ref="about-usps-home"
                href="http://about.usps.com/"
                rel="noopener noreferrer"
                target="_blank"
              >About USPS Home</a>
            </li>
            <li>
              <a
                ref="newsroom"
                href="http://about.usps.com/news/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Newsroom</a>
            </li>
            <li>
              <a
                ref="usps-service-updates"
                href="http://about.usps.com/news/service-alerts/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >USPS Service Updates</a>
            </li>
            <li>
              <a
                ref="forms-publications"
                href="http://about.usps.com/forms-publications/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Forms & Publications</a>
            </li>
            <li>
              <a
                ref="government-services"
                href="https://www.usps.com/gov-services/gov-services.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Government Services</a>
            </li>
          </ul>
        </div>
        <div class="app-footer__nav-group">
          <p class="app-footer__nav-heading">
            Other USPS Sites
          </p>
          <ul class="mt-1">
            <li>
              <a
                ref="business-customer-gateway"
                href="https://gateway.usps.com/"
                rel="noopener noreferrer"
                target="_blank"
              >Business Customer Gateway</a>
            </li>
            <li>
              <a
                ref="postal-inspectors"
                href="https://postalinspectors.uspis.gov/"
                rel="noopener noreferrer"
                target="_blank"
              >Postal Inspectors</a>
            </li>
            <li>
              <a
                ref="inspector-general"
                href="http://www.uspsoig.gov/"
                rel="noopener noreferrer"
                target="_blank"
              >Inspector General</a>
            </li>
            <li>
              <a
                ref="postal-explorer"
                href="http://pe.usps.gov/"
                rel="noopener noreferrer"
                target="_blank"
              >Postal Explorer</a>
            </li>
            <li>
              <a
                ref="national-postal-museum"
                href="http://www.postalmuseum.si.edu/"
                rel="noopener noreferrer"
                target="_blank"
              >National Postal Museum</a>
            </li>
            <li>
              <a
                ref="resources-for-developers"
                href="https://www.usps.com/webtools/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Resources for Developers</a>
            </li>
            <li>
              <a
                ref="postal-pro"
                href="https://postalpro.usps.com/"
                rel="noopener noreferrer"
                target="_blank"
              >PostalPro</a>
            </li>
          </ul>
        </div>
        <div class="app-footer__nav-group">
          <p class="app-footer__nav-heading">
            Legal Information
          </p>
          <ul class="mt-1">
            <li>
              <a
                ref="privacy-policy"
                href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Privacy Policy</a>
            </li>
            <li>
              <a
                ref="terms-of-use"
                href="http://about.usps.com/termsofuse.htm"
                rel="noopener noreferrer"
                target="_blank"
              >Terms of Use</a>
            </li>
            <li>
              <a
                ref="foia"
                href="http://about.usps.com/who-we-are/foia/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >FOIA</a>
            </li>
            <li>
              <a
                ref="no-fear-act"
                href="http://about.usps.com/who-we-are/no-fear-act/welcome.htm"
                rel="noopener noreferrer"
                target="_blank"
              >No FEAR Act EEO Data</a>
            </li>
          </ul>
        </div>
      </div>
      <p
        ref="copyright"
        class="text-sm mt-6"
      >
        Copyright &copy; {{ currentYear }} USPS. All Rights Reserved.
      </p>
      <div class="c-hairline mt-6" />
      <ul class="app-footer__social">
        <li>
          <a
            ref="facebook"
            href="http://www.facebook.com/usps"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :src="$options.facebookPNG"
              alt="USPS Facebook link"
              class="app-footer__social-icon"
            >
          </a>
        </li>
        <li class="ml-4">
          <a
            ref="twitter"
            href="http://twitter.com/usps"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :src="$options.twitterPNG"
              alt="USPS Twitter link"
              class="app-footer__social-icon"
            >
          </a>
        </li>
        <li class="ml-4">
          <a
            ref="pinterest"
            href="http://pinterest.com/uspsstamps"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :src="$options.pinterestPNG"
              alt="USPS Pinterest link"
              class="app-footer__social-icon"
            >
          </a>
        </li>
        <li class="ml-4">
          <a
            ref="youtube"
            href="http://www.youtube.com/usps"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :src="$options.youtubePNG"
              alt="USPS Youtube icon link"
              class="app-footer__social-icon"
            >
          </a>
        </li>
        <li class="ml-4">
          <a
            ref="instagram"
            href="http://www.instagram.com/uspostalservice"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :src="$options.instagramPNG"
              alt="USPS Instagram icon link"
              class="app-footer__social-icon"
            >
          </a>
        </li>
        <li class="ml-4">
          <a
            ref="threads"
            href="http://www.threads.net/@uspostalservice"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              :src="$options.threadsPNG"
              alt="USPS Threads icon link"
              class="app-footer__social-icon"
            >
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import facebookPNG from '../../assets/_logo-facebook.png';
import pinterestPNG from '../../assets/_logo-pinterest.png';
import twitterPNG from '../../assets/_logo-twitter.png';
import uspsSVG from '../../assets/_logo-usps.svg';
import youtubePNG from '../../assets/_logo-youtube.png';
import threadsPNG from '../../assets/_logo-threads.png';
import instagramPNG from '../../assets/_logo-instagram.png';

export default {
  name: 'AppFooter',
  props: {
    mgoUrl: {
      type: String,
      default: 'https://moversguide.usps.com',
    },
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    },
  },
  facebookPNG,
  pinterestPNG,
  twitterPNG,
  youtubePNG,
  uspsSVG,
  threadsPNG,
  instagramPNG,
};
</script>
