import {
  isLocalDev,
  isImagitasDev,
  isImagitasTest,
  isImagitasPreprod,
  isUspsDev,
  isUspsCat,
  isUspsSit,
  isUspsProdInternal,
} from '@mgo/core/src/environment';

/*
 * Switch True block usage in this file
 * https://medium.com/trabe/using-switch-true-in-javascript-986e8ad8ae4f
 */

export const getAccessOrigin = (origin) => {
  switch (true) {
    case isLocalDev(origin):
    case isImagitasDev(origin):
    case isImagitasTest(origin):
      return 'https://staging-app.mymove.com';

    default:
      return 'https://app.mymove.com';
  }
};

export const getMgoOrigin = (origin) => {
  switch (true) {
    case isLocalDev(origin):
    case isImagitasDev(origin):
      return /(mgo|coac)-dev/g.test(origin)
        ? 'https://mgo-dev.imagitas.com' : 'https://mgodev.imagitas.com';

    case isImagitasTest(origin):
      return /(mgo|coac)-test/g.test(origin)
        ? 'https://mgo-test.imagitas.com' : 'https://mgotest.imagitas.com';

    case isImagitasPreprod(origin):
      return /(mgo|coac)-prep/g.test(origin)
        ? 'https://mgo-prep.imagitas.com' : 'https://mgoprep.imagitas.com';

    case isUspsDev(origin):
      return 'https://origin-moversguide-dev.usps.com';

    case isUspsCat(origin):
      return 'https://origin-moversguide-cat.usps.com';

    case isUspsSit(origin):
      return 'https://origin-moversguide-sit.usps.com';

    case isUspsProdInternal(origin):
      return 'https://origin-moversguide.usps.com';

    default:
      return 'https://moversguide.usps.com';
  }
};

export const getCoacOrigin = (origin) => {
  switch (true) {
    case isLocalDev(origin):
    case isImagitasDev(origin):
      return /(mgo|coac)-dev/g.test(origin)
        ? 'https://coac-dev.imagitas.com' : 'https://coacdev.imagitas.com';

    case isImagitasTest(origin):
      return /(mgo|coac)-test/g.test(origin)
        ? 'https://coac-test.imagitas.com' : 'https://coactest.imagitas.com';

    case isImagitasPreprod(origin):
      return /(mgo|coac)-prep/g.test(origin)
        ? 'https://coac-prep.imagitas.com' : 'https://coacprep.imagitas.com';

    case isUspsDev(origin):
      return 'https://origin-managemymove-dev.usps.com';

    case isUspsCat(origin):
      return 'https://origin-managemymove-cat.usps.com';

    case isUspsSit(origin):
      return 'https://origin-managemymove-sit.usps.com';

    case isUspsProdInternal(origin):
      return 'https://origin-managemymove.usps.com';

    default:
      return 'https://managemymove.usps.com';
  }
};
