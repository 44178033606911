// LOCAL
export const isLocalDev = (origin) => /localhost/g.test(origin);
// MGO
export const isImagitasDev = (origin) => /(mgo|coac)(dev|dev01|-dev)/g.test(origin);
export const isImagitasTest = (origin) => /(mgo|coac)(test|tst02|-test)/g.test(origin);
export const isImagitasPreprod = (origin) => /(mgo|coac)(prep|preprod|-prep)/g.test(origin);
export const isUspsDev = (origin) => /origin-(moversguide|managemymove)-dev/g.test(origin);
export const isUspsCat = (origin) => /origin-(moversguide|managemymove)-cat/g.test(origin);
export const isUspsSit = (origin) => /origin-(moversguide|managemymove)-sit/g.test(origin);
export const isUspsProdInternal = (origin) => /origin-(moversguide|managemymove).usps/g.test(origin);
export const isUspsProd = (origin) => /\/(moversguide|managemymove).usps/g.test(origin) || isUspsProdInternal(origin);
