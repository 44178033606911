<template>
  <div
    id="app"
    class="flex flex-col min-h-screen"
    :class="`page__${$route.name}`"
  >
    <AppHeader />
    <div class="flex-grow">
      <AppLoader
        v-if="loading"
        modal
      />
      <router-view v-else />
    </div>
    <AppFooter :mgo-url="$options.mgoUrl" />
  </div>
</template>

<script>
import { AppLoader } from '@mgo/ui/lib/components';
import { isDesktop } from '@mgo/core/src/utils/deviceType';
import destinationManagerMixin from './mixins/destinationManager';

export default {
  name: 'App',
  components: {
    AppLoader,
    AppHeader: () => (isDesktop()
      ? import(/* webpackChunkName: "bootstrap" */ '@mgo/ui/lib/components').then((module) => {
        const { AppHeaderDesktop } = module;
        return AppHeaderDesktop;
      })
      : import(/* webpackChunkName: "bootstrap" */ '@mgo/ui/lib/components').then((module) => {
        const { AppHeaderMobile } = module;
        return AppHeaderMobile;
      })
    ),
    AppFooter: () => import(/* webpackChunkName: "bootstrap" */ '@mgo/ui/lib/components').then((module) => {
      const { AppFooter } = module;
      return AppFooter;
    }),
  },
  mixins: [destinationManagerMixin],
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    this.resetStore();
    const config = await this.$coac.configGet();
    this.$store.set('config', config || {});
    if (!this.$coac.visitId) {
      this.$coac.visitId = JSON.parse(sessionStorage.getItem('visitId'));
    }
    this.loading = false;
  },
  methods: {
    resetStore() {
      this.$store.dispatch('RESET_STATE');
    },
  },
};
</script>
