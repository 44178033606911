<template>
  <div class="c-banner">
    <div class="c-banner__container">
      <p class="c-banner__heading">
        <slot name="heading" />
      </p>
      <p class="c-banner__message">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
};
</script>
