import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';

import Vue from 'vue';
import CoacPlugin from '@mgo/core/src/plugins/coac';
import DisputePlugin from '@mgo/core/src/plugins/dispute';
import App from './App.vue';
import router from './router';
import store from './store';
import segment from './utils/segment';
import './css/index.css';

segment();

Vue.config.productionTip = false;

Vue.use(CoacPlugin, {
  api: '',
  postbackURL: `${window.location.origin}/mgoc/action?payment=success`,
});
Vue.use(DisputePlugin, {
  api: '',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
