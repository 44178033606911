<template>
  <div class="c-coa-item">
    <div class="c-coa-item__label">
      <template v-if="label">
        {{ label }}
      </template>
      <slot
        v-else
        name="label"
      />
    </div>
    <p class="c-coa-item__content">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'CoaItem',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
