import {
  getAccessOrigin,
  getCoacOrigin,
  getMgoOrigin,
} from '../utils/destinationManager';

export default {
  accessUrl: getAccessOrigin(window.location.origin),
  coacUrl: getCoacOrigin(window.location.origin),
  disputesUrl: `${getCoacOrigin(window.location.origin)}/mgoc/disputes`,
  mgoUrl: getMgoOrigin(window.location.origin),
};
