<template>
  <!-- eslint-disable max-len -->
  <div class="c-input-text">
    <label
      :for="name"
      class="c-input-text__label c-input-label"
    >{{ label }}</label>
    <input
      :id="name"
      ref="field"
      :value="formattedValue"
      :name="fieldName"
      :clear="clear"
      :placeholder="placeholder"
      :class="{ 'errors c-input-field--error': hasError }"
      :autocomplete="generatedAutocomplete"
      :disabled="disabled"
      type="text"
      class="c-input-text__field c-input-field"
      @focus="hasFocus=true;"
      @blur="blur($event); $emit('blur', $event);"
      @input="handleInput($event.target.value);"
      @keyup.up="keyUp"
      @keyup.down="keyDown"
      @keypress.enter="keyEnter"
    >
    <span
      v-if="attachClear && !disabled"
      :id="`clear_${name}`"
      class="c-input-text__clear"
      @click="$emit('clear',name)"
    >
      &times;
    </span>
    <p
      :id="`${name}-error`"
      class="c-input-text__helper c-input-helper"
    >
      <slot name="helper" />
    </p>
    <slot />
    <transition name="fade">
      <div
        v-if="hasFocus && options"
        class="c-dropdown"
      >
        <div
          v-for="option in options"
          :key="transformCompare(option)"
          class="c-dropdown__option"
          :class="{ 'c-dropdown__option-focused': focusedOption && transformCompare(option) === transformCompare(focusedOption) }"
          @click="select(option)"
        >
          {{ transformLabel(option) }}
        </div>
      </div>
    </transition>
    <a
      href="https://tools.usps.com/go/ZipLookupAction_input"
      rel="noopener noreferrer"
      target="_blank"
      class="font-bold block mt-2 text-base"
      :class="{
        'c-link': controlExperience,
        'c-link--choice': !controlExperience,
        'c-link--inline': !controlExperience,
      }"
    >I don't know my ZIP Code™</a>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  name: 'InputZipCode',
  props: {
    controlExperience: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Label',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    /* eslint-disable no-console */
    asyncFn: {
      type: Function,
      default() { return Promise.resolve(); },
    },
    transformCompare: {
      type: Function,
      default: (option) => option,
    },
    transformLabel: {
      type: Function,
      default: (option) => option,
    },
    transformValue: {
      type: Function,
      default: (option) => option,
    },
    delay: {
      type: Number,
      default: 200,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    /* eslint-enable no-console */
  },
  data() {
    return {
      hasFocus: false,
      options: [],
      focusedOption: null,
      debounce: null,
    };
  },
  computed: {
    attachClear() {
      return this.clear && this.value.length >= 1;
    },
    formattedValue() {
      return this.formatValue(this.value);
    },
    fieldName() {
      return uuid();
    },
    generatedAutocomplete() {
      if (navigator.userAgent.indexOf('Chrome') < 0) return 'off';
      return this.fieldName;
    },
  },
  methods: {

    formatValue(value) {
      if (!value) return '';
      const chars = value.split('');
      return chars.length > 5 ? value.substring(0, 5) : value;
    },
    handleInput(event) {
      const value = event
        .replace(/\D/g, '')
        .substring(0, 5);

      // update listbox when less than 5 chars or if 5 chars and input changed
      if (value?.length < 5 || (value?.length === 5 && this.value !== value)) {
        this.search(value);
      }

      this.$emit('input', value);
      this.$refs.field.value = this.formatValue(value);
    },
    select(option) {
      this.$emit('input', this.transformValue(option));
      this.$emit('select', option);
    },
    search(event) {
      if (this.debounce) {
        clearTimeout(this.debounce);
      }

      this.debounce = setTimeout(() => {
        this.asyncFn(event)
          .then((response) => {
            this.options = response;
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.warn(e);
            this.options = [];
          });
      }, this.delay);
    },
    keyUp() {
      if (!this.focusedOption) {
        this.focusedOption = this.options[this.options.length - 1];
        return;
      }

      const focusedIndex = this.options
        .findIndex(
          (option) => this.transformCompare(option) === this.transformCompare(this.focusedOption),
        );

      this.focusedOption = this.options[focusedIndex - 1];

      if (!this.focusedOption) {
        this.focusedOption = this.options[this.options.length - 1];
      }
    },
    keyDown() {
      if (!this.focusedOption) {
        [this.focusedOption] = this.options;
        return;
      }

      const focusedIndex = this.options
        .findIndex(
          (option) => this.transformCompare(option) === this.transformCompare(this.focusedOption),
        );

      this.focusedOption = this.options[focusedIndex + 1];

      if (!this.focusedOption) {
        [this.focusedOption] = this.options;
      }
    },
    keyEnter(event) {
      if (this.focusedOption) {
        event.stopPropagation();
        event.preventDefault();
        event.target.blur();
        this.select(this.focusedOption);
      }
    },
    blur() {
      setTimeout(() => {
        this.hasFocus = false;
        this.focusedOption = null;
      }, 50);
    },
  },
};
</script>
