<template>
  <transition name="fade">
    <div
      id="sticky-individual-progress-bar-with-steps"
      class="individual-progress-bar-sticky"
    >
      <div
        v-if="mobile"
        class="mobile-progress-bar-header"
      >
        <p
          class="current-step"
        >
          {{ simplifySteps[currentStep - 1].label }}
        </p>
        <p
          v-if="currentStep < 5"
          class="next-step"
        >
          Up Next: {{ simplifySteps[currentStep].label }}
        </p>
      </div>
      <ol
        v-else
        class="individual-progress-bar-step"
      >
        <template
          v-for="item in simplifySteps"
        >
          <IndividualProgressBarStepItem
            :key="item.step"
            v-bind="item"
          />
        </template>
      </ol>
      <individual-progress-bar
        class="simplify_progress"
        :aria-valuemax="1"
        :aria-valuemin="0"
        :aria-valuenow="valueNow"
        :value="valueNow"
      />
    </div>
  </transition>
</template>

<script>
import IndividualProgressBar from './IndividualProgressBar.vue';
import IndividualProgressBarStepItem from './IndividualProgressBarStepItem.vue';
import addressBlue from './address-info-blue.svg';
import contactBlue from './contact-info-blue.svg';
import contactGrey from './contact-info-grey.svg';
import mailBlue from './mail-services-blue.svg';
import mailGrey from './mail-services-grey.svg';
import payBlue from './verifyandpay-blue.svg';
import payGrey from './verifyandpay-grey.svg';
import dealsBlue from './dealsandconfirm-blue.svg';
import dealsGrey from './dealsandconfirm-grey.svg';
import checkmark from './check-grey.svg';

const simplify = [
  {
    id: 1,
    alt: '',
    isActive: false,
    label: 'Address Info',
  },
  {
    id: 2,
    alt: '',
    isActive: false,
    label: 'Contact Info',
  },
  {
    id: 3,
    alt: '',
    isActive: false,
    label: 'Mail Services',
  },
  {
    id: 4,
    alt: '',
    isActive: false,
    label: 'Verify & Pay',
  },
  {
    id: 5,
    alt: '',
    isActive: false,
    label: 'Deals & Confirmation',
  },
];


export default {
  name: 'IndividualProgressBarWithSteps',
  components: { IndividualProgressBar, IndividualProgressBarStepItem },
  props: {
    mobile: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      totalSteps: 5,
      simplifySteps: simplify.map((step) => ({
        ...step, ...this.parseSource({ ...step, totalSteps: 5 }),
      })),
    };
  },
  computed: {
    currentStep() {
      return this.$route.meta.currentStep;
    },
    valueNow() {
      return this.currentStep / this.totalSteps;
    },
  },
  watch: {
    $route: 'handleStep',
  },
  created() {
    this.handleStep('created');
  },
  methods: {
    async handleStep() {
      this.simplifySteps = this.simplifySteps.map((step) => ({
        ...step, imageSrc: this.imageSrc(step),
      }));
    },
    parseSource(step) {
      const { currentStep } = this.$route.meta;
      const isCompleted = (step.id < currentStep) && currentStep <= step.totalSteps;
      Object.assign(step, { isCompleted });
      const source = [this.colorSwitch(step), this.imageSwitch(step)].join('-');
      const isActive = source.includes(this.$route.name);
      return { isActive, source, isCompleted };
    },
    colorSwitch: ({ isActive, isCompleted }) => {
      if (isCompleted) return 'checkmark';
      if (isActive) return 'blue';
      return 'grey';
    },
    imageSwitch: ({ id, isCompleted }) => {
      if (isCompleted) return 'checkmark';
      switch (id) {
        default:
          return 'checkmark';
        case 1:
          return 'address-info';
        case 2:
          return 'contact-info';
        case 3:
          return 'additional-services';
        case 4:
          return 'verify-pay|verify-info|verify-info-read|verify-info-edit|identity-check';
        case 5:
          return 'deals-confirmation|instant-savings|confirm-order';
      }
    },
    imageSrc({ source, isActive }) {
      if (isActive && source.includes('confirm-order') && !window.location.href.includes('instant-savings')) {
        return checkmark;
      }
      if (isActive && source.includes('address-info')) {
        return addressBlue;
      }
      if (isActive && source.includes('contact-info')) {
        return contactBlue;
      }
      if (!isActive && source.includes('contact-info')) {
        return contactGrey;
      }
      if (isActive && source.includes('additional-services')) {
        return mailBlue;
      }
      if (!isActive && source.includes('additional-services')) {
        return mailGrey;
      }
      if (isActive && source.includes('verify-info')) {
        return payBlue;
      }
      if (!isActive && source.includes('verify-info')) {
        return payGrey;
      }
      if (isActive && (source.includes('deals-confirm') || source.includes('instant-savings'))) {
        return dealsBlue;
      }
      if (!isActive && (source.includes('deals-confirm') || source.includes('instant-savings'))) {
        return dealsGrey;
      }
      return checkmark;
    },
  },
};
</script>
