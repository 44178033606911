/* eslint-disable class-methods-use-this, prefer-destructuring */
import { isDesktop } from '../../../../@mgo/client/src/utils/deviceType';

const getOrderUuid = () => sessionStorage.getItem('coacUuid');

/**
 * Get context object for FE analytics events
 */
const getContext = () => {
  const orderUuid = getOrderUuid();
  const deviceType = isDesktop() ? 'DESKTOP' : 'MOBILE';

  return {
    context: {
      ip: '0.0.0.0',
    },
    deviceType,
    orderUuid,
    origin: 'MGO',
  };
};

class AnalyticsQueue {
  queue = [];

  hasIdentity() {
    return !!sessionStorage.getItem('coacUuid');
  }

  identify(userId) {
    window.analytics.identify(
      userId,
      {},
      { ...getContext() },
    );
  }

  async page(routeName, {
    path,
    name,
    query,
    title,
    url,
  }) {
    const isIdentified = this.hasIdentity();
    const pageEventCb = () => window.analytics.page(
      routeName,
      {
        path, name, query, title, url,
      },
      { ...getContext() },
    );

    if (!isIdentified) {
      this.queue.push(pageEventCb);
    } else {
      await this.reconcile();
      pageEventCb();
    }
  }

  async track(event, payload = {}) {
    const trackEventCb = () => window.analytics.track(
      event,
      { ...payload },
      { ...getContext() },
    );

    if (!this.hasIdentity()) {
      this.queue.push(trackEventCb);
    } else {
      await this.reconcile();
      trackEventCb();
    }
  }

  async reconcile() {
    if (this.queue.length) {
      const event = await this.queue.shift();
      event();
      return this.reconcile();
    }
    return Promise.resolve(true);
  }
}

export const eventQueueSingleton = new AnalyticsQueue();
export const identify = eventQueueSingleton.identify;
export const page = eventQueueSingleton.page.bind(eventQueueSingleton);
export const track = eventQueueSingleton.track.bind(eventQueueSingleton);
