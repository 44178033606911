<template>
  <!-- eslint-disable max-len -->
  <div
    :class="[inputRadioClass]"
  >
    <!-- TODO: these composed classes will likely get purged in prod, we should find another way to do this -->
    <input
      :id="`${name}-${formValue.toLowerCase().replace(/ /g, '-')}`"
      type="radio"
      :class="`${inputRadioClass}__field`"
      :name="name"
      :value="formValue"
      :checked="value === formValue"
      :disabled="disabled"
      style="pointer-events: none"
      @keyup.prevent="$emit('input', formValue)"
    >
    <slot name="prelabel" />
    <label
      :for="`${name}-${formValue.toLowerCase().replace(/ /g, '-')}`"
      :class="`${inputRadioClass}__label c-input-label`"
      @click="buttonClick()"
    >
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputRadio',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    formValue: {
      type: String,
      required: true,
    },
    tabindex: {
      type: String,
      default: '0',
    },
    button: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // update the base class based on prop boolean
    inputRadioClass() {
      return this.button ? 'c-input-radio-button' : 'c-input-radio';
    },
  },
  methods: {
    buttonClick() {
      return this.disabled ? null : this.$emit('input', this.formValue);
    },
  },
};
</script>
