import axios from 'axios';

/**
 * Standard HTTP Handler
 * @type {Object}
 */
const XHR = axios.create();

const controller = new AbortController();
/**
 * Pass data from response to promise
 * @param  {Object} data [description]
 * @return {Object}      [description]
 */
const responseHandler = ({ data }) => data;

XHR.interceptors.request.use((req) => {
  if (req?.url === '/mgo-api/api/v1/coa') {
    const coa = localStorage.getItem('ajs_user_id');
    if (coa) {
      controller.abort();
      return req;
    }
  }
  return req;
});
/**
 * Pass errors to promise
 * @param  {???} error [description]
 * @return {String}       [description]
 */
const errorHandler = ({ config, response, ...error }) => {
  let { msg, code } = error;

  if (response.status === 400 && !response.data) {
    if (config.url.indexOf('/coa/')) {
      Object.assign(response, {
        data: {
          exception: 'CoaExpired',
        },
      });
    }
  }

  if (!msg) {
    msg = 'There was an issue with your request. Please try again.';
  }

  if (!code) {
    code = '???';
  }

  return { error: msg, xhr: response.data, raw: response };
};

/**
 * Post to XHR
 * @param  {String} url    [description]
 * @param  {Object} params [description]
 * @param  {Object} headers [description]
 * @return {Promise}       [description]
 */
export const post = (url, params, headers) => XHR.post(url, { ...params }, { ...headers })
  .then(responseHandler)
  .catch(errorHandler);

/**
 * Put to XHR
 * @param  {String} url    [description]
 * @param  {Object} params [description]
 * @param  {Object} headers [description]
 * @return {Promise}       [description]
 */
export const put = (url, params, headers) => XHR.put(url, { ...params }, { ...headers })
  .then(responseHandler)
  .catch(errorHandler);

/**
 * Get to XHR
 * @param  {[type]} url    [description]
 * @param  {[type]} params [description]
 * @return {[type]}        [description]
 */
export const get = (url, params, headers) => XHR.get(url, {
  ...params,
  ...headers,
})
  .then(responseHandler)
  .catch(errorHandler);

/**
 * Delete to XHR
 * @param  {[type]} url    [description]
 * @param  {[type]} params [description]
 * @return {[type]}        [description]
 */
export const del = (url, params, headers) => XHR.delete(url, {
  ...params,
  ...headers,
})
  .then(responseHandler)
  .catch(errorHandler);

export default XHR;
