<template>
  <div class="c-input-datepicker">
    <label
      v-if="label"
      :for="name"
      class="c-input-datepicker__label"
    >{{ label }}</label>
    <!-- Inline Datepicker Variant -->
    <datepicker
      v-if="inline"
      :value="datepickerValue"
      :disabled-dates="disabledDates"
      :open-date="openDate"
      format="MM/dd/yyyy"
      placeholder="mm/dd/yyyy"
      tabindex="1"
      type="text"
      full-month-name
      inline
      @selected="handlePickerInput"
    />
    <!-- Standard Datepicker Variant -->
    <template v-else>
      <input
        v-if="desktop"
        :id="name"
        ref="inputField"
        :value="displayValue"
        :class="{ 'errors c-input-field--error': hasError }"
        :name="name"
        maxlength="10"
        placeholder="mm/dd/yyyy"
        type="text"
        class="c-input-datepicker__field"
        autocomplete="off"
        :disabled="disabled"
        @focus="openDatepicker"
        @blur="closeDatepicker"
        @input="handleTextInput($event.target.value)"
      >
      <button
        v-else
        :id="name"
        ref="inputField"
        type="button"
        :class="{ 'errors c-input-field--error': hasError }"
        class="c-input-datepicker__field"
        :disabled="disabled"
        @click="openDatepicker"
      >
        {{ displayValue || 'Select Date' }}
      </button>
      <img
        :src="desktop ? $options.calendarSVG : $options.chevronSVG"
        aria-hidden="true"
        alt=""
        class="c-input-datepicker__icon"
      >
      <p
        :id="`${name}-error`"
        class="c-input-datepicker__helper"
      >
        <slot name="helper" />
      </p>
      <!-- Desktop -->
      <template
        v-if="desktop"
        class="relative"
      >
        <div
          v-if="tooltipOpen"
          class="c-input-datepicker__tooltip"
        >
          <datepicker
            :value="datepickerValue"
            :disabled-dates="disabledDates"
            :open-date="openDate"
            format="MM/dd/yyyy"
            placeholder="mm/dd/yyyy"
            tabindex="1"
            type="text"
            full-month-name
            inline
            @selected="handlePickerInput"
          />
        </div>
      </template>
      <!-- Mobile -->
      <Modal
        v-else
        v-model="modalOpen"
      >
        <div class="text-sm text-center py-4">
          <slot name="rules" />
        </div>
        <datepicker
          :value="datepickerValue"
          :disabled-dates="disabledDates"
          :open-date="openDate"
          format="MM/dd/yyyy"
          placeholder="mm/dd/yyyy"
          tabindex="1"
          type="text"
          full-month-name
          inline
          @selected="handlePickerInput"
        />
        <div class="text-center mt-3">
          {{ label }}
        </div>
        <div
          v-if="value"
          class="mt-3 text-center font-bold"
        >
          <p>
            {{ displayValue }}
          </p>
          <button
            :id="`${name}-modal-continue`"
            type="button"
            class="c-btn mt-3"
            @click="modalOpen = false"
          >
            Select & Continue
          </button>
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import {
  format, isMatch, isValid, parse, parseISO,
} from 'date-fns';
import Datepicker from './vuejs-datepicker';
import Modal from './modal/index.vue';
import chevronSVG from '../assets/_icon-chevron.svg';
import calendarSVG from '../assets/_icon-calendar.svg';

export default {
  name: 'InputDatePicker',
  components: {
    Datepicker,
    Modal,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    max: {
      type: Date,
      required: true,
    },
    min: {
      type: Date,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    openDate: {
      type: Date,
      default: () => new Date(),
    },
    desktop: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalOpen: false,
      tooltipOpen: false,
    };
  },
  computed: {
    disabledDates() {
      return {
        to: this.min,
        from: this.max,
      };
    },
    displayValue() {
      if (!this.value) return '';
      return this.formatInputDisplay(this.value);
    },
    datepickerValue() {
      if (!this.value) return '';
      return parse(this.value, 'yyyy-MM-dd', new Date());
    },
  },
  methods: {
    // Format user input for display 'mm/dd/yyyy'
    formatInputDisplay(value) {
      if (!this.desktop) {
        const formatted = format(this.datepickerValue, 'cccc, LLLL dd, yyyy');
        return formatted || '';
      }

      // Full date string value provided return early
      if (isMatch(value, 'yyyy-MM-dd')) {
        return format(parseISO(value), 'MM/dd/yyyy');
      }

      return value
        .split('')
        .reduce((date, char, i) => {
          switch (i) {
            case 0:
              return `${char}`;
            case 2:
            case 4:
              return `${date}/${char}`;
            default:
              return `${date}${char}`;
          }
        }, '');
    },
    // Format user input for value emit 'yyyy-mm-dd'
    formatInputEmit(value) {
      if (!value) return '';

      // get todays date chars in same order as input value chars
      const todayChars = format(new Date(), 'MM-dd-yyyy').split('');
      const valChars = value.split('');

      // Set input chars to form valid date string
      // Ex. ['1','2', '3','1','1','9','9','9'] 12311999
      const formatChars = todayChars.map((char, i) => (valChars[i] ? valChars[i] : char));
      const yyyy = formatChars.join('').slice(4, 8); // 1999
      const mm = formatChars.join('').slice(0, 2); // 12
      const dd = formatChars.join('').slice(2, 4); // 31

      return `${yyyy}-${mm}-${dd}`;
    },
    /**
     * @param date - JS Date Obj
     */
    handlePickerInput(date) {
      if (!date) {
        this.$emit('input', '');
        this.closeDatepicker();
        this.$refs.inputField.blur();
        return;
      }
      this.$emit('input', format(date, 'yyyy-MM-dd'));
      if (this.desktop) {
        this.closeDatepicker();
        this.$refs.inputField.blur();
      }
    },
    handleTextInput(event) {
      const value = event
        .replace(/\D/g, '')
        .substring(0, 8);

      // Emit input event when empty value or full length date string provided
      if (!value || (value?.length === 8)) {
        const formatted = this.formatInputEmit(value);
        this.$emit('input', isValid(parseISO(formatted)) ? formatted : '');
      }
      // Populate field with formatted display value
      this.$refs.inputField.value = this.formatInputDisplay(value);
    },
    openDatepicker() {
      if (this.desktop) {
        this.tooltipOpen = true;
      } else {
        this.modalOpen = true;
      }
    },
    closeDatepicker() {
      this.modalOpen = false;
      this.tooltipOpen = false;
    },
  },
  chevronSVG,
  calendarSVG,
};
</script>

<style>
.rtl {
  direction: rtl;
}
.vdp-datepicker {
  position: relative;
  text-align: left;
}
.vdp-datepicker * {
  box-sizing: border-box;
}
.vdp-datepicker__calendar {
  position: absolute;
  z-index: 100;
  background: #fff;
  width: 300px;
  border: 1px solid #ccc;
}
.vdp-datepicker__calendar header {
  display: block;
  line-height: 40px;
}
.vdp-datepicker__calendar header span {
  display: inline-block;
  text-align: center;
  width: 71.42857142857143%;
  float: left;
}
.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .next {
  width: 14.285714285714286%;
  float: left;
  text-indent: -10000px;
  position: relative;
}
.vdp-datepicker__calendar header .prev:after,
.vdp-datepicker__calendar header .next:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  border: 6px solid transparent;
}
.vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid #000;
  margin-left: -5px;
}
.vdp-datepicker__calendar header .prev.disabled:after {
  border-right: 10px solid #ddd;
}
.vdp-datepicker__calendar header .next:after {
  border-left: 10px solid #000;
  margin-left: 5px;
}
.vdp-datepicker__calendar header .next.disabled:after {
  border-left: 10px solid #ddd;
}
.vdp-datepicker__calendar header .prev:not(.disabled),
.vdp-datepicker__calendar header .next:not(.disabled),
.vdp-datepicker__calendar header .up:not(.disabled) {
  cursor: pointer;
}
.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
  background: #eee;
}
.vdp-datepicker__calendar .disabled {
  color: #ddd;
  cursor: default;
}
.vdp-datepicker__calendar .flex-rtl {
  display: flex;
  width: inherit;
  flex-wrap: wrap;
}
.vdp-datepicker__calendar .cell {
  display: inline-block;
  padding: 0 5px;
  width: 14.285714285714286%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year {
  cursor: pointer;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #4bd;
}
.vdp-datepicker__calendar .cell.selected {
  background: #4bd;
}
.vdp-datepicker__calendar .cell.selected:hover {
  background: #4bd;
}
.vdp-datepicker__calendar .cell.selected.highlighted {
  background: #4bd;
}
.vdp-datepicker__calendar .cell.highlighted {
  background: #cae5ed;
}
.vdp-datepicker__calendar .cell.highlighted.disabled {
  color: #a3a3a3;
}
.vdp-datepicker__calendar .cell.grey {
  color: #888;
}
.vdp-datepicker__calendar .cell.grey:hover {
  background: inherit;
}
.vdp-datepicker__calendar .cell.day-header {
  font-size: 75%;
  white-space: nowrap;
  cursor: inherit;
}
.vdp-datepicker__calendar .cell.day-header:hover {
  background: inherit;
}
.vdp-datepicker__calendar .month,
.vdp-datepicker__calendar .year {
  width: 33.333%;
}
.vdp-datepicker__clear-button,
.vdp-datepicker__calendar-button {
  cursor: pointer;
  font-style: normal;
}
.vdp-datepicker__clear-button.disabled,
.vdp-datepicker__calendar-button.disabled {
  color: #999;
  cursor: default;
}

</style>
