<template>
  <transition name="fade">
    <div
      v-if="open"
      class="c-drawer"
    >
      <div class="c-drawer__content">
        <slot />
      </div>
      <div class="c-drawer__backdrop" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    open(currentOpen) {
      if (currentOpen) {
        this.disablePageScrolling();
      } else {
        this.enablePageScrolling();
      }
    },
  },
  created() {
    if (this.open) {
      this.disablePageScrolling();
    }
  },
  beforeDestroy() {
    this.enablePageScrolling();
  },
  methods: {
    disablePageScrolling() {
      document.body.classList.add('overflow-hidden');
    },
    enablePageScrolling() {
      document.body.classList.remove('overflow-hidden');
    },
  },
};
</script>
