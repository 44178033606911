<template>
  <div
    :class="{ 'app-header-mobile--slide-right': isDrawerOpen }"
    class="app-header-mobile u-no-print"
  >
    <Drawer
      ref="drawer"
      :open="isDrawerOpen"
      class="py-2"
    >
      <ul
        ref="drawer-links"
        class="app-header-mobile__links"
      >
        <li class="app-header-mobile__link-item">
          <a
            ref="sign-in"
            href="https://reg.usps.com/login?appURL=https%3A%2F%2Fm.usps.com%2Fm%2FHome"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.signInSVG"
              alt="Sign in icon"
              class="app-header-mobile__icon"
            >
            Sign In or Register
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="track"
            href="https://m.usps.com/m/TrackConfirmAction"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.trackSVG"
              alt="Track icon"
              class="app-header-mobile__icon"
            >
            Track
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="informed-delivery"
            href="https://informeddelivery.usps.com/box/pages/intro/start.action"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.informedDeliverySVG"
              alt="Informed Delivery icon"
              class="app-header-mobile__icon"
            >
            Informed Delivery
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="stamps"
            href="https://store.usps.com/m"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.stampsSVG"
              alt="Stamps icon"
              class="app-header-mobile__icon"
            >
            Buy Stamps
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="prices"
            href="https://m.usps.com/m/Prices"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.pricesSVG"
              alt="Prices icon"
              class="app-header-mobile__icon"
            >
            Prices
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="click-n-ship"
            href="https://cns.usps.com/index.html"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.clickNShipSVG"
              alt="Click-N-Ship icon"
              class="app-header-mobile__icon"
            >
            Click-N-Ship®
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="locations"
            href="https://m.usps.com/m/POLocatorAction"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.locationsSVG"
              alt="Locations icon"
              class="app-header-mobile__icon"
            >
            Locations
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="zip-codes"
            href="https://m.usps.com/m/ZipLookupAction"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.zipCodesSVG"
              alt="ZIP Codes icon"
              class="app-header-mobile__icon"
            >
            ZIP Codes<sup>™</sup>
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="schedule-pickup"
            href="https://m.usps.com/m/ScheduleAPickupAction"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.schedulePickupSVG"
              alt="Schedule pickup icon"
              class="app-header-mobile__icon"
            >
            Schedule Pickup
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="hold-mail"
            href="https://m.usps.com/m/HoldMail"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.holdMailSVG"
              alt="Hold mail icon"
              class="app-header-mobile__icon"
            >
            Hold Mail
          </a>
        </li>
        <li class="app-header-mobile__link-item">
          <a
            ref="change-my-address"
            href="https://moversguide.usps.com/?referral=MGOMOBILE"
            rel="noopener"
            target="_blank"
            class="app-header-mobile__link"
          >
            <img
              :src="$options.changeMyAddressSVG"
              alt="Change my address icon"
              class="app-header-mobile__icon"
            >
            Change My Address
          </a>
        </li>
      </ul>
      <ul
        ref="drawer-legal-links"
        class="app-header-mobile__legal-links"
      >
        <li class="app-header-mobile__legal-item mt-2">
          <a
            ref="privacy-policy"
            href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__legal-link"
          >Privacy Policy</a>
        </li>
        <li class="app-header-mobile__legal-item mt-2">
          <a
            ref="terms-and-conditions"
            href="https://m.usps.com/m/TermsConditions"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__legal-link"
          >Terms and Conditions</a>
        </li>
        <li class="app-header-mobile__legal-item mt-2">
          <a
            ref="contact-us"
            href="tel:1-800-275-8777"
            target="_self"
            class="app-header-mobile__legal-link"
          >Contact Us</a>
        </li>
        <li class="app-header-mobile__legal-item mt-2">
          <a
            ref="full-site"
            href="https://www.usps.com/"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-mobile__legal-link"
          >Full Site</a>
        </li>
        <li
          ref="copyright"
          class="app-header-mobile__legal-item mt-2"
        >
          &copy; {{ currentYear }} USPS
        </li>
      </ul>
    </Drawer>
    <header class="app-header-mobile__outer">
      <div class="app-header-mobile__inner">
        <button
          ref="hamburger"
          :class="{ 'c-hamburger--active': isDrawerOpen }"
          class="c-hamburger"
          @click="isDrawerOpen = !isDrawerOpen"
        >
          <span class="c-hamburger__icon" />
          <span class="sr-only">{{ isDrawerOpen ? 'Close Menu' : 'Open Menu' }}</span>
        </button>
        <img
          ref="usps-logo"
          :src="$options.uspsEagleOnlySVG"
          alt="The profile of an eagle's head."
          class="app-header-mobile__logo"
        >
      </div>
    </header>
  </div>
</template>

<script>
import Drawer from '../drawer/index.vue';
import changeMyAddressSVG from '../../assets/_icon-change-my-address.svg';
import clickNShipSVG from '../../assets/_icon-click-n-ship.svg';
import holdMailSVG from '../../assets/_icon-hold-mail.svg';
import informedDeliverySVG from '../../assets/_icon-informed-delivery.svg';
import locationsSVG from '../../assets/_icon-locations.svg';
import pricesSVG from '../../assets/_icon-prices.svg';
import schedulePickupSVG from '../../assets/_icon-schedule-pickup.svg';
import signInSVG from '../../assets/_icon-sign-in.svg';
import stampsSVG from '../../assets/_icon-stamps.svg';
import trackSVG from '../../assets/_icon-track.svg';
import uspsEagleOnlySVG from '../../assets/_logo-usps-eagle-only.svg';
import zipCodesSVG from '../../assets/_icon-zip-codes.svg';

export default {
  name: 'AppHeaderMobile',
  components: {
    Drawer,
  },
  data() {
    return {
      isDrawerOpen: false,
    };
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    },
  },
  changeMyAddressSVG,
  clickNShipSVG,
  holdMailSVG,
  informedDeliverySVG,
  locationsSVG,
  pricesSVG,
  schedulePickupSVG,
  signInSVG,
  stampsSVG,
  trackSVG,
  uspsEagleOnlySVG,
  zipCodesSVG,
};
</script>
