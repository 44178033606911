<template>
  <div class="c-input-text">
    <label
      :for="name"
      class="c-input-text__label c-input-label"
    >{{ label }}</label>
    <input
      :id="name"
      ref="field"
      :value="formattedValue"
      :placeholder="placeholder"
      :name="name"
      :class="{ 'errors c-input-field--error': hasError }"
      class="c-input-text__field c-input-field"
      type="text"
      maxlen="13"
      v-bind="$attrs"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @input="handleInput($event.target.value)"
    >
    <p
      :id="`${name}-error`"
      class="c-input-text__helper c-input-helper"
    >
      <slot name="helper" />
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputCoaKey',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Label',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      return this.formatValue(this.value);
    },
  },
  methods: {
    formatValue(value) {
      return value
        .split('')
        .reduce((coaKey, char, indx) => {
          switch (indx) {
            case 3:
            case 6:
            case 9:
              return `${coaKey}-${char}`;

            default:
              return `${coaKey}${char}`;
          }
        }, '');
    },
    handleInput(event) {
      const value = event
        .replace(/\D/g, '')
        .substring(0, 13);

      this.$emit('input', value);
      this.$refs.field.value = this.formatValue(value);
    },
  },
};
</script>
