<template>
  <!-- eslint-disable max-len -->
  <InputText
    autocomplete="off"
    v-bind="$attrs"
    :disabled="disabled"
    @focus="hasFocus = true; $emit('focus', $event);"
    @blur="blur($event); $emit('blur', $event);"
    @input="search($event); $emit('input', $event)"
    @keyup.native.up="keyUp"
    @keyup.native.down="keyDown"
    @keypress.native.enter="keyEnter"
    @clear="clearChild"
  >
    <slot slot="helper" />
    <transition name="fade">
      <div
        v-if="hasFocus"
        class="c-dropdown"
      >
        <div
          v-for="option in options"
          :key="transformCompare(option)"
          class="c-dropdown__option"
          :class="{ 'c-dropdown__option-focused': focusedOption && transformCompare(option) === transformCompare(focusedOption)}"
          @click="select(option)"
        >
          {{ transformLabel(option) }}
        </div>
      </div>
    </transition>
  </InputText>
</template>

<script>
import InputText from './input-text.vue';

export default {
  name: 'InputListBox',
  components: {
    InputText,
  },
  props: {
    /* eslint-disable no-console */
    asyncFn: {
      type: Function,
      default: (event) => { console.log(event); },
    },
    transformCompare: {
      type: Function,
      default: (option) => option,
    },
    transformLabel: {
      type: Function,
      default: (option) => option,
    },
    transformValue: {
      type: Function,
      default: (option) => option,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 200,
    },
    /* eslint-enable no-console */
  },
  data() {
    return {
      hasFocus: false,
      options: [],
      focusedOption: null,
      debounce: null,
    };
  },
  methods: {
    clearChild(payload) {
      const toKebobCase = (str) => str.replace(/-./g, (x) => x.toUpperCase()[1]);
      const transformed = toKebobCase(payload);
      this.$emit('clear', transformed);
    },
    select(option) {
      this.$emit('input', this.transformValue(option));
      this.$emit('select', option);
    },
    filterDups(data) {
      try {
        if (data) {
          const uniq = data?.reduce((a, b) => {
            if (a?.filter((x) => x?.label === b?.label)?.length === 0) a.push(b);
            return a;
          }, []);
          return uniq;
        }
      } catch (e) {
        window.console.warn(`filtering duplicates failed input: ${data} error: ${e}`);
      }
      return data;
    },
    search(event) {
      if (this.debounce) {
        clearTimeout(this.debounce);
      }

      this.debounce = setTimeout(() => {
        this.asyncFn(event)
          .then((response) => {
            this.options = this.filterDups(response);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.warn(e);
            this.options = [];
          });
      }, this.delay);
    },
    keyUp() {
      if (!this.focusedOption) {
        this.focusedOption = this.options[this.options.length - 1];
        return;
      }

      const focusedIndex = this.options
        .findIndex(
          (option) => this.transformCompare(option) === this.transformCompare(this.focusedOption),
        );

      this.focusedOption = this.options[focusedIndex - 1];

      if (!this.focusedOption) {
        this.focusedOption = this.options[this.options.length - 1];
      }
    },
    keyDown() {
      if (!this.focusedOption) {
        [this.focusedOption] = this.options;
        return;
      }

      const focusedIndex = this.options
        .findIndex(
          (option) => this.transformCompare(option) === this.transformCompare(this.focusedOption),
        );

      this.focusedOption = this.options[focusedIndex + 1];

      if (!this.focusedOption) {
        [this.focusedOption] = this.options;
      }
    },
    keyEnter(event) {
      if (this.focusedOption) {
        event.stopPropagation();
        event.preventDefault();
        event.target.blur();
        this.select(this.focusedOption);
      }
    },
    blur() {
      setTimeout(() => {
        this.hasFocus = false;
        this.focusedOption = null;
      }, 50);
    },
  },
};
</script>
