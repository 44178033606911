<template>
  <div class="app-header-desktop u-no-print">
    <div class="app-header-desktop__content">
      <a
        ref="usps-logo-link"
        href="https://www.usps.com/"
        rel="noopener noreferrer"
        target="_blank"
        class="app-header-desktop__logo-link"
      >
        <img
          ref="usps-logo"
          :src="$options.uspsSVG"
          alt="The profile of an eagle's head adjoining the words USPS.com."
          class="app-header-desktop__logo"
        >
      </a>
      <ul class="app-header-desktop__nav-list">
        <li
          ref="usps-secure"
          class="app-header-desktop__nav-item app-header-desktop__nav-item--with-icon"
        >
          <span class="app-header-desktop__icon-lock" />
          Secure & Private
        </li>
        <li class="app-header-desktop__nav-item app-header-desktop__nav-item--divided-right">
          <a
            ref="usps-home-link"
            href="https://www.usps.com/"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-desktop__nav-link"
          >USPS HOME</a>
        </li>
        <li class="app-header-desktop__nav-item">
          <a
            ref="usps-faq-link"
            href="https://www.usps.com/faqs/changeofaddress-faqs.htm"
            rel="noopener noreferrer"
            target="_blank"
            class="app-header-desktop__nav-link"
          >FAQs</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import uspsSVG from '../../assets/_logo-usps.svg';

export default {
  name: 'AppHeaderDesktop',
  uspsSVG,
};
</script>
