<template>
  <div
    :class="{ 'c-input-dropdown--labeled': label }"
    class="c-input-dropdown"
  >
    <label
      :for="name"
      class="c-input-dropdown__label"
    >{{ label }}</label>
    <div class="relative flex flex-col">
      <select
        :id="name"
        :value="value"
        :class="{
          'errors c-input-dropdown--error': hasError,
          'c-input-dropdown--error-bg': hasError && !hasFocus,
        }"
        :name="fieldName"
        :autocomplete="generatedAutocomplete"
        :disabled="disabled"
        class="c-input-dropdown__field"
        @focus="hasFocus = true;"
        @blur="hasFocus = false;"
        @change="$emit('input', $event.target.value)"
      >
        <slot />
      </select>
      <img
        :src="$options.chevronSVG"
        alt=""
        class="c-input-dropdown__chevron"
        aria-hidden="true"
      >
    </div>
    <p
      :id="`${name}-error`"
      class="c-input-dropdown__helper"
    >
      <slot name="helper" />
    </p>
  </div>
</template>

<script>
// eslint-disable-next-line
import { v4 as uuid } from 'uuid';
import chevronSVG from '../assets/_icon-chevron.svg';

export default {
  name: 'InputDropdown',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  computed: {
    fieldName() {
      return this.autocomplete !== 'on' ? uuid() : this.name;
    },
    generatedAutocomplete() {
      return this.autocomplete !== 'on' ? this.fieldName : 'on';
    },
  },
  chevronSVG,
};
</script>
