const deviceType = () => {
  const mobileMediaQuery = matchMedia('screen and (min-width: 0px) and (max-width: 1023px)');
  return mobileMediaQuery.matches ? 'mobile' : 'desktop';
};

export const deviceOS = () => {
  /* eslint-disable-next-line */
  const userAgent = /\(([^)]+?)\;/.exec(window.navigator.userAgent)[1];

  if (deviceType() === 'mobile') {
    const iOS = ['iPhone', 'iPad', 'iPod'];
    if (iOS.some((device) => userAgent.includes(device))) {
      return 'iOS';
    }
    if (userAgent.includes('Linux')) {
      return 'Android';
    }
    return 'Mobile Other';
  }

  if (deviceType() === 'desktop') {
    if (userAgent.includes('Macintosh')) {
      return 'Mac OS';
    }
    if (userAgent.includes('Windows')) {
      return 'Windows';
    }
    return 'Desktop Other';
  }

  return 'Other';
};

export const isDesktop = () => deviceType() === 'desktop';

export default deviceType;
