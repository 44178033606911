<template>
  <div class="app-loader">
    <Modal
      v-if="modal"
      :value="modal"
      :close-x="false"
      dialog-class="w-1/5"
    >
      <p class="app-loader__message">
        {{ message }}
      </p>
      <ProgressBar
        :steps="totalSteps"
        :current="currentStep"
        short
        class="mt-2 p-0"
      />
    </Modal>
    <div
      v-else
      class="app-loader__container"
    >
      <p class="app-loader__message">
        {{ message }}
      </p>
      <ProgressBar
        :steps="totalSteps"
        :current="currentStep"
        short
        class="mt-2 p-0"
      />
    </div>
  </div>
</template>

<script>
import Modal from './modal/index.vue';
import ProgressBar from './progress-bar/index.vue';

export default {
  name: 'AppLoader',
  components: {
    Modal,
    ProgressBar,
  },
  props: {
    interval: {
      type: Number,
      required: false,
      default: 1000,
    },
    message: {
      type: String,
      required: false,
      default: 'Loading...',
    },
    steps: {
      type: Number,
      required: false,
      default: 5,
    },
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      totalSteps: 5,
      currentStep: 0,
      timer: null,
    };
  },
  mounted() {
    this.animateProgressBar();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    animateProgressBar() {
      // set the value to the props for more customization
      this.currentStep = 0;
      this.totalSteps = this.steps;
      this.nextFrame();
    },
    nextFrame() {
      this.timer = setTimeout(() => {
        this.currentStep = this.currentStep < this.totalSteps ? this.currentStep + 1 : 0;
        this.nextFrame();
      }, this.interval);
    },
  },
};
</script>
