/* eslint-disable no-underscore-dangle */
import './dispute.d';
import {
  addHours,
  isAfter,
  parseISO,
} from 'date-fns';
import {
  get,
  post,
} from '../http';

const notAuthenticatedError = new Error('api token is expired, or not present. unable to get a new one');
const notAuthenticatedErrorPromise = Promise.resolve(notAuthenticatedError);

/**
 * An API layer for COAC API requests
 */
export default class Dispute {
  /**
   * @param {String} api - the fully qualified domain to issue requests against
   */
  constructor({
    api = null,
  }) {
    /**
     * The fully qualified base url for the MGO API
     * @type {String}
     */
    this.api = api;

    /**
     * COA order confirmation number
     * Obtained through login with Validation letter key/zip combo
     * @type {String}
     */
    this.cfpsId = sessionStorage.getItem('coaccfpsid') || '';

    /**
     * 13 digit key from COA Validation Letter
     * @type {String}
     */
    this.coaKey = sessionStorage.getItem('coackey') || '';

    /**
     * ZIP code on the COA Validation Letter
     * @type {String}
     */
    this.loginZip = sessionStorage.getItem('coaccode') || '';

    /**
     * The Visit Id of the current session
     * @type {Number}
     */
    this.visitId = null;

    /**
     * Used to authorize requests against the MGO API
     * @type {String}
     */
    this.token = '';
  }

  /**
   * Determines if the API is authenticated
   * @return {Boolean}
   */
  _isAuthenticated() {
    this._restoreCachedToken();
    return !!this.token;
  }

  /**
   * Used to Authorize the MGO API for all subsequent requests
   * Will set the class instance token on success
   * @return {Promise<Boolean>)}
   */
  async _getAuthToken(clientId = 'mgo-app') {
    if (this._isAuthenticated()) {
      return Promise.resolve(true);
    }

    const { access_token: token } = await post(`${this.api}/mgo-auth/oauth/token`, {}, {
      params: {
        grant_type: 'client_credentials',
        client_id: clientId,
      },
    });

    this.token = token || '';
    this._setTokenCache(this.token, addHours(new Date(), 1));

    if (!this.token) {
      // eslint-disable-next-line no-console
      console.error(new Error('could not authenticate api'));
    }

    return Promise.resolve(!!this.token);
  }

  // eslint-disable-next-line class-methods-use-this
  _setTokenCache(token, expiresOn) {
    sessionStorage.setItem('apit', token);
    sessionStorage.setItem('apitexp', expiresOn);
  }

  _restoreCachedToken() {
    const token = sessionStorage.getItem('apit') || '';
    const expiresOn = sessionStorage.getItem('apitexp') || '';

    if (expiresOn && isAfter(new Date(), parseISO(expiresOn))) {
      this.token = '';
      return;
    }

    if (token) {
      this.token = token;
    }
  }

  reset() {
    this.cfpsId = '';
    this.coaKey = '';
    this.loginZip = '';
    this.visitId = null;
  }

  /**
   * Retrieve Application Configuration Details
   * These config items relate to Command Center
   * @method configGet
   * @return {Promise<Config>}
   */
  async configGet() {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    return get(`${this.api}/mgo-api/api/v1/app/config`, {}, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }

  /**
   * COA Dispute Actions
   * ----------------------------------------------------------------
   */

  /**
   * Login to existing COA order
   * @param {String} coaKey - 13 digit key from COA Validation Letter
   * @param {String} zip5 - ZIP code on the COA Validation Letter
   * @return {Promise<String>}
   */
  async login(coaKey, zip5) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const {
      cfpsId,
      moverType,
      newZip,
      raw,
    } = await get(`${this.api}/mgo-api/api/v1/dispute?coaKey=${coaKey}&oldZip=${zip5}`, {}, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (raw?.data?.error) {
      return Promise.resolve(raw?.data);
    }

    this.cfpsId = cfpsId;
    this.coaKey = coaKey;
    this.loginZip = zip5;
    this.newZip = newZip;

    sessionStorage.setItem('coaccfpsid', cfpsId);
    sessionStorage.setItem('coackey', coaKey);
    sessionStorage.setItem('coaccode', zip5);
    sessionStorage.setItem('moverType', moverType);

    return Promise.resolve('');
  }

  /**
   * Search for existing COA data
   * @return {Promise<DisputeSearchResponse>}
   */
  async search() {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    try {
      return get(`${this.api}/mgo-api/api/v1/dispute?coaKey=${this.coaKey}&oldZip=${this.loginZip}`, {}, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    } catch (err) {
      return Promise.resolve(err);
    }
  }

  /**
   * Submit a dispute for the COA order
   * @param {String} firstName
   * @param {String} lastName
   * @param {String} email
   * @param {String} phone
   * @param {String} preferredContactMethod - EMAIL | PHONE
   * @param {String} suffix
   * @param {String} title
   * @param {String} streetAddress
   * @param {String} city
   * @param {String} state
   * @param {String} zipCode
   * @param {Boolean} addressee
   * @param {Boolean} filedCoa
   * @param {Boolean} jointAddressee
   * @param {Boolean} nameRecognition
   * @param {Boolean} newResident
   * @param {Boolean} previousResident
   * @param {Boolean} remainAtAddress
   * @return {Promise<String>}
   */
  async submit({
    firstName,
    lastName,
    moverType,
    email,
    phone,
    preferredContactMethod,
    suffix = null,
    title = null,
    streetAddress,
    city,
    state,
    addressee = null,
    filedCoa = null,
    jointAddressee = null,
    nameRecognition = null,
    newResident = null,
    previousResident = null,
    remainAtAddress = null,
  }) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    let url = 'dispute/partial-dispute';

    if (firstName && lastName) {
      url = 'dispute';
    }
    const { raw } = await post(`${this.api}/mgo-api/api/v1/${url}`, {
      cfpsId: this.cfpsId,
      coaKey: this.coaKey,
      loginZip: this.loginZip,
      newZipCode: this.newZip,
      firstName,
      lastName,
      moverType,
      email,
      phone,
      preferredContactMethod,
      suffix,
      title,
      streetAddress,
      city,
      state,
      zipCode: this.loginZip,
      addressee,
      filedCoa,
      jointAddressee,
      nameRecognition,
      newResident,
      previousResident,
      remainAtAddress,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (raw?.data?.error) {
      return Promise.resolve(raw?.data);
    }

    return Promise.resolve('');
  }


  /**
   * Analytics Events
   * ----------------------------------------------------------------
   */

  /**
   * Get the visitId
   * @param {String} deviceType - DESKTOP | MOBILE
   * @param {String} queryString - url query string
   * @return {Promise<Number>}
   */
  async visit(deviceType, queryString) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const visitId = await post(
      `${this.api}/mgo-api/api/v1/e/v`,
      {
        params: {
          qs: queryString,
          deviceType,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    this.visitId = visitId;

    return Promise.resolve(visitId);
  }
}
