<template>
  <details
    ref="details"
    class="c-accordion"
    @click="handleDetailsClick"
  >
    <summary class="c-accordion__summary">
      <div class="c-accordion__summary-content">
        <span>{{ summary }}</span>
        <span
          :class="isOpen ? 'c-accordion__icon--open' : 'c-accordion__icon--closed'"
          class="c-accordion__icon"
        />
      </div>
    </summary>
    <slot />
  </details>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    summary: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleDetailsClick() {
      const prevState = this.$refs.details.hasAttribute('open');
      this.isOpen = !prevState;
    },
  },
};
</script>
