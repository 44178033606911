<template>
  <InputDropdown
    v-bind="$attrs"
    :has-error="hasError"
    :disabled="disabled"
    autocomplete="off"
    @input="$emit('input', $event)"
  >
    <option value="">
      Select
    </option>
    <option value="AK">
      Alaska (AK)
    </option>
    <option value="AL">
      Alabama (AL)
    </option>
    <option value="AR">
      Arkansas (AR)
    </option>
    <option value="AZ">
      Arizona (AZ)
    </option>
    <option value="CA">
      California (CA)
    </option>
    <option value="CO">
      Colorado (CO)
    </option>
    <option value="CT">
      Connecticut (CT)
    </option>
    <option value="DC">
      District of Columbia (DC)
    </option>
    <option value="DE">
      Delaware (DE)
    </option>
    <option value="FL">
      Florida (FL)
    </option>
    <option value="GA">
      Georgia (GA)
    </option>
    <option value="HI">
      Hawaii (HI)
    </option>
    <option value="IA">
      Iowa (IA)
    </option>
    <option value="ID">
      Idaho (ID)
    </option>
    <option value="IL">
      Illinois (IL)
    </option>
    <option value="IN">
      Indiana (IN)
    </option>
    <option value="KS">
      Kansas (KS)
    </option>
    <option value="KY">
      Kentucky (KY)
    </option>
    <option value="LA">
      Louisiana (LA)
    </option>
    <option value="MA">
      Massachusetts (MA)
    </option>
    <option value="MD">
      Maryland (MD)
    </option>
    <option value="ME">
      Maine (ME)
    </option>
    <option value="MI">
      Michigan (MI)
    </option>
    <option value="MN">
      Minnesota (MN)
    </option>
    <option value="MO">
      Missouri (MO)
    </option>
    <option value="MS">
      Mississippi (MS)
    </option>
    <option value="MT">
      Montana (MT)
    </option>
    <option value="NC">
      North Carolina (NC)
    </option>
    <option value="ND">
      North Dakota (ND)
    </option>
    <option value="NE">
      Nebraska (NE)
    </option>
    <option value="NH">
      New Hampshire (NH)
    </option>
    <option value="NJ">
      New Jersey (NJ)
    </option>
    <option value="NM">
      New Mexico (NM)
    </option>
    <option value="NV">
      Nevada (NV)
    </option>
    <option value="NY">
      New York (NY)
    </option>
    <option value="OH">
      Ohio (OH)
    </option>
    <option value="OK">
      Oklahoma (OK)
    </option>
    <option value="OR">
      Oregon (OR)
    </option>
    <option value="PA">
      Pennsylvania (PA)
    </option>
    <option value="RI">
      Rhode Island (RI)
    </option>
    <option value="SC">
      South Carolina (SC)
    </option>
    <option value="SD">
      South Dakota (SD)
    </option>
    <option value="TN">
      Tennessee (TN)
    </option>
    <option value="TX">
      Texas (TX)
    </option>
    <option value="UT">
      Utah (UT)
    </option>
    <option value="VA">
      Virginia (VA)
    </option>
    <option value="VT">
      Vermont (VT)
    </option>
    <option value="WA">
      Washington (WA)
    </option>
    <option value="WI">
      Wisconsin (WI)
    </option>
    <option value="WV">
      West Virginia (WV)
    </option>
    <option value="WY">
      Wyoming (WY)
    </option>
    <option disabled="disabled">
      --------------
    </option>
    <option value="AA">
      Armed Forces (the) Americas (AA)
    </option>
    <option value="AE">
      Armed Forces Europe (AE)
    </option>
    <option value="AP">
      Armed Forces Pacific (AP)
    </option>
    <option disabled="disabled">
      --------------
    </option>
    <option value="AS">
      American Samoa (AS)
    </option>
    <option value="FM">
      Federated States of Micronesia (FM)
    </option>
    <option value="GU">
      Guam (GU)
    </option>
    <option value="MH">
      Marshall Islands (MH)
    </option>
    <option value="MP">
      Northern Mariana Islands (MP)
    </option>
    <option value="PW">
      Palau (PW)
    </option>
    <option value="PR">
      Puerto Rico (PR)
    </option>
    <option value="VI">
      Virgin Islands (VI)
    </option>
    <slot
      v-for="(_, helper) in $slots"
      :slot="helper"
      :name="helper"
    />
  </InputDropdown>
</template>

<script>
import InputDropdown from './input-dropdown.vue';

export default {
  name: 'InputState',
  components: {
    InputDropdown,
  },
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
