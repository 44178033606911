import Dispute from '../class/dispute';

export default {
  install(Vue, options = {}) {
    /** @memberof Vue.prototype */
    const dispute = new Dispute(options);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$dispute = dispute;
  },
};
